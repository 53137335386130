import { Grid, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'

export const Head = () => (
    <SEO
        title="安い、安心、高品質のミスプラチナのこだわり"
        description="安い結婚指輪、婚約指輪は試着もできる安心通販のMissPlatinum。高品質にこだわった１万円台～の結婚指輪！６万円台～の婚約指輪！と安い価格帯で高品質な人気のブライダルリングを通販、ご来店でもご利用頂けます♪"
        page="quality"
    />
)

const QualityPage: React.FC = () => {
    const muiTheme = useTheme()
    return (
        <Layout breadcrumbs={[{ name: '品質保証' }]}>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12} component="main">
                    <H1>
                        <StaticImage
                            src="../images/quality/m8.jpg"
                            alt="品質保証"
                            width={640}
                        />
                    </H1>
                    <Wrapper theme={muiTheme}>
                        <H2 theme={muiTheme}>
                            <Txt size="s">地金はPT950 ハードプラチナを使用</Txt>
                        </H2>
                        <Body theme={muiTheme}>
                            <ImageWrapper>
                                <StaticImage
                                    src="../images/quality/platinum.jpg"
                                    alt=""
                                    width={190}
                                    imgStyle={{ width: '190px' }}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    ブライダルジュエリーでは定番のPT950ハードプラチナは価値と強度を両立した一生の記念品に相応しいワンランク上の材質。お好みでPT900もご利用頂けます。また、カジュアルなゴールドとして人気のK18ホワイトゴールドや、イエローゴールド、ピンクゴールドまでご希望に合わせて承ります。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                    <Wrapper theme={muiTheme}>
                        <H2 theme={muiTheme}>
                            <Txt size="s">品質保証書・リペアー保証書付</Txt>
                        </H2>
                        <Body theme={muiTheme}>
                            <ImageWrapper>
                                <StaticImage
                                    src="../images/quality/hoshousho.jpg"
                                    alt=""
                                    width={190}
                                    imgStyle={{ width: '190px' }}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    地金の材質（PT950ハードプラチナ）、天然石（ダイヤモンド）等、製品の品質を表記した保証書付。サイズ直しや修理などのアフターサービスも都度承っております。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                    <Wrapper theme={muiTheme}>
                        <H2 theme={muiTheme}>
                            <Txt size="s">
                                エンゲージリングはダイヤグレードを証明する鑑別書または鑑定書付{' '}
                            </Txt>
                        </H2>
                        <Body theme={muiTheme}>
                            <ImageWrapper>
                                <StaticImage
                                    src="../images/quality/kantei.jpg"
                                    alt=""
                                    width={190}
                                    imgStyle={{ width: '190px' }}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    ダイヤモンドは国内の代表的な鑑別機関を通した確かな品質のみを使用。カラット、カラー、クラリティ、カットの4C全て表記された鑑定書、製品の品質保証書もご用意。標準クラスのダイヤはE～Hカラー
                                    / VeryGood～Good /
                                    SIクラス～、無色透明で肉眼レベルで内包物のないダイヤですが、お好みで最高級グレードでのお見積りも承っております。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                    <Wrapper theme={muiTheme}>
                        <H2 theme={muiTheme}>
                            <Txt size="s">納期について</Txt>
                        </H2>
                        <Body theme={muiTheme}>
                            <ImageWrapper>
                                <StaticImage
                                    src="../images/quality/nouki.jpg"
                                    alt=""
                                    width={190}
                                    imgStyle={{ width: '190px' }}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    ご注文完了日から2～3週間以内に発送となります。試着や展示に使用されていない完全な新品が完成次第、発送のご連絡をさせて頂きます。
                                </Txt>
                                <br />
                                <Txt size="s">
                                    ※年末年始、お盆期間、その他大型連休を挟む場合は4週間ほど納期を頂く場合がございます。
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                    <Wrapper theme={muiTheme}>
                        <H2 theme={muiTheme}>
                            <Txt size="s">安心の返金、返品保証</Txt>
                        </H2>
                        <Body theme={muiTheme}>
                            <ImageWrapper>
                                <StaticImage
                                    src="../images/quality/ko.jpg"
                                    alt=""
                                    width={190}
                                    imgStyle={{ width: '190px' }}
                                    objectFit="contain"
                                />
                            </ImageWrapper>
                            <Description>
                                <Txt size="s">
                                    万が一イメージと異なる、デザインを変更したいなどの場合の返金、返品、交換も納品後1週間以内のご連絡で対応させて頂きます。サイズ直しや修理などのアフターサービスも都度承っております。
                                </Txt>
                                <br />
                                <Txt size="s">
                                    サイズ直し1本5,400円 / 新品仕上げ5,400円 /
                                    留め直し5,400円（別途石代有料）
                                </Txt>
                            </Description>
                        </Body>
                    </Wrapper>
                </Grid>
            </Grid>
        </Layout>
    )
}

const H1 = styled('h1')({
    margin: 0,
})
const Wrapper = styled('section')(({ theme }) => ({
    margin: '40px 8px 0',
    border: `1px solid ${theme.palette.secondary.main}`,
}))
const H2 = styled('h2')(({ theme }) => ({
    margin: 0,
    padding: '10px',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    lineHeight: 1,
}))
const Body = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
    },
}))
const ImageWrapper = styled('div')({
    minWidth: '190px',
    display: 'flex',
    justifyContent: 'center',
    margin: '12px',
})
const Description = styled('p')({
    margin: '12px',
})

export default QualityPage
